:root {
  --dark-jq: #1E1E1E;
  --light-jq:#3E3E3E;
  --muted-jq:#2E2E2E;
  --text-jq:#fff;
  --placeholder-jq:#999;

}


html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--dark-jq);
  color:var(--text-jq);
}

.dark-jq {
	background-color: var(--dark-jq);
}
.light-jq {
	background-color: var(--light-jq);
}


.btn-close {
	color:var(text-jq);
}

a {
	/* style inherit from text */
	color:inherit;
	text-decoration: none;

}
a:hover {
	color:inherit;
	text-decoration: none;
}

a:visited {
	color:inherit;
	text-decoration: none;
}
a:active {
	color:inherit;
	text-decoration: none;
}
a:focus {
	color:inherit;
	text-decoration: none;
}

#root {
	height: 100%;
}

input,textarea {
	background-color:var(--dark-jq) !important;
    border: none !important;
    outline: none !important;
	border-radius: 0 !important;
	color: var(--text-jq)!important;
	accent-color: var(--dark-jq) !important ;
}
select {
	background-color:var(--dark-jq) !important;
    border: none !important;
    outline: none !important;
	border-radius: 0 !important;
	color: var(--text-jq)!important;
}

::placeholder {
  color: var(--placeholder-jq) !important;
}

.modal-dialog, .modal-content, .modal-footer, .modal-header{
	background-color:var(--light-jq) !important;
	color: var(--text-jq)!important;
	border: none !important;
	outline: none !important;
}

.dropdown-menu {
	background-color:var(--dark-jq) !important;
	color: var(--text-jq)!important;
	border: none !important;
	outline: none !important;
}
.dropdown-item {
	color: var(--text-jq)!important;
	border: none !important;
	outline: none !important;
	background-color:var(--dark-jq) !important;
}

.dropdown-item.active{
	background-color:var(--light-jq) !important;
}

.text-selected-jq{
	background-color:var(--muted-jq) !important;
}

.btn-primary {
	background-color:var(--dark-jq) !important;
	border:none;

	border-radius:0
}
/* Dark mode styles */

input[type="file"] {
  background-color: #333333;
  color: #ffffff;
  border: none;
}

input[type="file"]::file-selector-button {
  background-color: #444444;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
}

input[type="file"]::file-selector-button:hover {
  background-color: #555555;
}
